import ng from 'angular';

// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('savings', {
      redirectTo: '.transfers',
      parent: 'root',
      url: '/savings',
      templateUrl: 'apps/savings/templates/acp-savings.ng.html',
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.apps.savings" */ 'apps/savings').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('savings.transfers', {
      redirectTo: {
        // Redirect to the initial state if navigated directly here
        state: '.transferTab'
      },
      templateUrl: 'apps/savings/templates/acp-savings-transfer.ng.html',
      controller: 'AcpSavingsTransferCtrl',
      data: {
        permissions: {
          only: 'hasSavingsEnrolled',
          redirectTo: {
            hasSavingsEnrolled: 'savings.enroll'
          }
        }
      },
      resolve: {
        savingsTermsUrl: /* @ngInject */ function(acpTermsModel) {
          return acpTermsModel.getSavingsTermsUrl();
        }
      }
    })
    .state('savings.transfers.transferTab', {
      template:
        '<acp-savings-money-transfer savings-terms-url="$resolve.savingsTermsUrl"/>',
      params: {
        prefills: null
      }
    })

    .state('savings.transfers.editAutomaticTransfer', {
      template:
        '<acp-savings-auto-transfer-edit savings-terms-url="$resolve.savingsTermsUrl"/>',
      params: {
        automaticTransferType: null,
        amount: null
      }
    })

    .state('savings.transfers.createGoal', {
      template:
        '<acp-savings-goal savings-terms-url="$resolve.savingsTermsUrl"/>'
    })

    .state('savings.transfers.viewGoal', {
      template:
        '<acp-savings-goal-progress savings-terms-url="$resolve.savingsTermsUrl">',
      data: {
        permissions: {
          only: 'hasGoalCreated',
          redirectTo: {
            hasGoalCreated: 'savings.transfers.createGoal'
          }
        }
      }
    })

    .state('savings.enroll', {
      redirectTo: {
        // Redirect to the initial state if navigated directly here
        state: '.loading',
        params: {
          nextState: '^.offering'
        }
      },
      data: {
        permissions: {
          except: 'hasSavingsEnrolled',
          redirectTo: {
            hasSavingsEnrolled: 'savings.transfers'
          }
        }
      },
      url: '/enroll',
      templateUrl: 'apps/savings/templates/acp-savings-enroll.ng.html',
      resolve: {
        $$w9Compliant: /* @ngInject */ function(nsPermissions) {
          return nsPermissions.requestPermission('isSavingsW9Compliant');
        },
        $$savingsData: /* @ngInject */ function(module, acpSavingsModel) {
          return acpSavingsModel.getSavingsInfo();
        },
        $savingsEnrollment: /* @ngInject */ function(
          acpSavingsEnrollmentModel,
          $$savingsData,
          $$w9Compliant
        ) {
          return acpSavingsEnrollmentModel($$savingsData, $$w9Compliant);
        },
        $stepManager: /* @ngInject */ function(
          acpStepStateManager,
          $savingsEnrollment
        ) {
          return acpStepStateManager(
            [
              { state: '.offering' },
              {
                state:
                  '.paywalls' /*, when: ... It would be nice to be able to include conditionally but
             paywalls does not have a simple programmatic way to see if paywalls are active. Instead, <acp-savings-paywalls>
             will auto .continue() immediately if there are no paywalls active. */
              },
              { state: '.terms-conditions' },
              {
                state: '.w9-agreement',
                /* @ngInject */ when: function($savingsEnrollment) {
                  return !$savingsEnrollment.w9Compliant;
                }
              },
              { state: '.completed' }
            ],
            'savings.enroll',
            {
              $savingsEnrollment: $savingsEnrollment
            }
          );
        },
        $$goToStep: /* @ngInject */ function($state, $stateParams) {
          return function(step) {
            if (step) {
              $state.go(
                'savings.enroll.loading',
                ng.extend(
                  {
                    nextState: step.name,
                    nextParams: ng.copy($stateParams)
                  },
                  $stateParams
                )
              );
            }
          };
        },
        $cancel: /* @ngInject */ function($state) {
          return function() {
            $state.go('savings');
          };
        },
        $next: /* @ngInject */ function($stepManager, $$goToStep) {
          return function() {
            $$goToStep($stepManager.getStepState(1));
          };
        }
      }
    })
    .state('savings.enroll.loading', {
      template: '<acp-savings-enrollment-loading />',
      params: {
        nextState: null,
        nextParams: null
      },
      controller: /* @ngInject */ function($state, $stateParams, $timeout) {
        // Immediately redirect and show loader until transition has finished
        $timeout(function() {
          $state.go($stateParams.nextState, $stateParams.nextParams, {
            location: 'replace'
          });
        });
      }
    })
    .state('savings.enroll.offering', {
      template:
        '<acp-savings-offering can-enroll="$resolve.$savingsEnrollment.canEnroll" reopen-date="$resolve.$savingsEnrollment.reopenDate" rate-interest="$resolve.$savingsEnrollment.rateInterest" continue="$resolve.$next()"/>',
      data: {
        permissions: {
          except: 'hasSavingsEnrolled',
          redirectTo: {
            hasSavingsEnrolled: 'savings.transfers'
          }
        }
      }
    })
    .state('savings.enroll.paywalls', {
      template:
        '<acp-savings-paywalls continue="$resolve.$next()" back="$resolve.$cancel()"/>'
    })
    .state('savings.enroll.terms-conditions', {
      template:
        '<acp-savings-terms-conditions terms-accepted="$resolve.$savingsEnrollment.termsAccepted" continue="$resolve.$next()" back="$resolve.$cancel()"/>'
    })
    .state('savings.enroll.w9-agreement', {
      template:
        '<acp-savings-w9-agreement ssn="$resolve.$savingsEnrollment.ssn" us-resident-agreement="$resolve.$savingsEnrollment.usResidentAgreement" irs-agreement="$resolve.$savingsEnrollment.irsAgreement" correct-ssn-agreement="$resolve.$savingsEnrollment.correctSsnAgreement" continue="$resolve.$next()" back="$resolve.$cancel()"/>'
    })
    .state('savings.enroll.completed', {
      template:
        '<acp-savings-enrollment-complete complete="$resolve.$savingsEnrollment.enroll()" continue="$resolve.$next()" back="$resolve.$back()" />',
      resolve: {
        $next: /* @ngInject */ function($state) {
          return function() {
            $state.go('savings.transfers', null, { reload: true });
          };
        },
        $back: /* ngInject */ function($state) {
          return function() {
            $state.go('dashboard');
          };
        }
      }
    });
}

export default states;
